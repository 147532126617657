<template>
  <NuxtLink :to="data?.destination?.attributes.urlPath">
    <MosaicCard
      class="card"
      :image="data?.destination?.attributes.image?.data?.attributes?.url ?? ''"
      :height="428"
      :width="280"
    >
      <div class="thumbnail-container">
        <ShadowedString
          v-if="data?.destination?.attributes.title"
          class="title"
          :text="data?.destination.attributes.title"
        />
        <button class="discover">Découvrir</button>
        <div class="icon-container">
          <Icon
            v-if="geographicZoneIcon"
            :name="geographicZoneIcon"
            height="42px"
            width="42px"
            class="geographic-zone-slug-icon"
          />
        </div>
      </div>
    </MosaicCard>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { getGeographicZonePicto } from '@/lib/getGeographicZonePicto';

import { Category } from '@/lib/strapi-types/Category';

const { data } = defineProps<{
  data: { destination: Category; geographicZoneSlug?: string; countrySlugs?: string[] };
}>();

const geographicZoneIcon = data?.geographicZoneSlug
  ? getGeographicZonePicto({
      geographicZoneSlug: data?.geographicZoneSlug,
      countrySlugs: data?.countrySlugs
    })
  : undefined;
</script>

<style lang="scss" scoped>
@use '$/components/shadowed-text.scss';
@use '$/border-radius.scss';
@use '$/button.scss';
@use '$/colors.scss';
@use '$/breakpoints.scss';

.card {
  width: 280px;
  height: 428px;

  .thumbnail-container {
    position: relative;
    z-index: 2;
    transform: translateZ(0);

    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    height: 100%;
    padding-bottom: 64px;

    color: white;

    background: rgb(0 0 0 / 10%);
    border-radius: border-radius.$small;

    .title {
      @include shadowed-text.small();

      margin-bottom: 16px;
    }

    .discover {
      @include button.outlined(white, $color-background: none, $color-hover: colors.$green);
    }

    .icon-container {
      position: absolute;
      bottom: -32px;

      align-items: center;
      justify-content: center;

      width: 68px;
      height: 68px;

      background-color: colors.$grey-100;
      border-radius: 100%;
    }
  }
}

@include breakpoints.mobile() {
  .card {
    width: 100%;
    height: 431px;
  }
}
</style>
